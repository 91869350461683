/* @font-face {
  font-family: 'CustomFont';
  src: url('../Striker.woff2') format('woff2');
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: 'CustomFont-Symbols';
  src: url('../GlitchSlap.woff2') format('woff2');
  unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
} */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  color: #ffffff;
  background-color:#06040a;
  font-size: 1.3em;
  zoom: reset;
  
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: #e6e6e6;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures the loader is on top of other elements */
}


.loader {
  border: 16px solid #847e7e;
  border-top: 16px solid #d9d9d9;
  border-radius: 50%;
  margin-top: 2rem;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.outer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
 
}

*, *::before, *::after {
  box-sizing: inherit;
}

.day-container {
  display: flex;
  align-items: center;
}

.day {
  flex-shrink: 0;
}

.toggle-icon {
  width: 0;
  height: 0;
  margin-left: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-right: 12px solid transparent;
  border-top: 15px solid #e6e6e6;
  border-left: 12px solid transparent;
  border-radius: 5px 5px;
}

.toggle-icon-artist {
  width: 0;
  height: 0;
  margin-left: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-right: 12px solid transparent;
  border-top: 15px solid #e6e6e6;
  border-left: 12px solid transparent;
  border-radius: 5px 5px;
}

h1 {
  font-weight: 1;
  letter-spacing: 0.12em;
}

.collapsed {
  transform: rotate(-90deg);
}

.headerContainer {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  position: relative; 
}

.eventsHeader, .artistsHeader {
  /* Your current styles, for instance: */
  color: #f4f4f4;
  font-weight: lighter !important;
  /* and potentially: */
  margin: 0; /* To reduce any default margin that <div> might have */
}

.headerContainer::before {
  content: ''; /* Mandatory for pseudo-elements */
  position: absolute; /* Absolute positioning */
  top: 30px; /* Start at the top of the container */
  bottom: 30px; /* Stretch to the bottom of the container */
  left: 50%; /* Center it horizontally */
  border-left: 1px solid white; /* Create the vertical line */
  transform: translateX(-50%); /* Ensure the line is truly centered */
}

.search {
  text-align: left;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

.searchBar {
  padding: 15px;
  background-color: #0c020a; /* a slight transparency to the container */
  text-align: center;
  font-size: 1rem;
  border: none;
  outline: none;
  margin-bottom: 10px;
  box-shadow: none;
  color: #ffffff;
  border-bottom: 1px solid white;

}

.container {
  position: relative;
  z-index: 1;
  max-width: 70vw;
  background-color: #0c020a; /* a slight transparency to the container */
  margin: 0 auto;  /* This will center your container */
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.list li {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px; /* space between itrems */
  padding: 10px;
  background-color: #222222;
}

.venue {
  margin: .25rem;
  text-transform: uppercase; 
  color: #e6e6e6;
  font-size: 0.9rem;
}

.venue-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.25rem;
  text-transform: uppercase;
  color: #e6e6e6;
  font-size: 0.9rem;
}

.share-icon {
  margin-left: auto;
  width: 20px;
  height: auto;
}

.tickets {
  margin: 1rem;
  text-transform: uppercase; 
  color: rgb(255, 255, 255);
  font-size: 1.1rem;
  letter-spacing: 0.17em;
  font-weight: 500;
  text-decoration: none;
}

.tickets-link {
  text-decoration: none;
}

.title {
  margin: .2rem;
  text-transform: uppercase; 
  color: #d8d8d8;
  font-size: 0.7rem;
}

.artists {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 100; /* slightly heavier font weight for artist names */
  color: #eeebe8;
  overflow: hidden;
  width: 85vw;
  text-overflow: ellipsis;
  text-align: center;  
  font-size: 1.2rem;
}

.artist-container {
  margin: 5px;
  padding: 5px;
}

.artist-name {
  font-size: 0.8rem;
  margin: 5px;
  padding: 5px;
  text-overflow: ellipsis;
}

.artist-playlist {
  font-size: 0.8rem;
  margin: 5px;
  padding: 5px;
  font-weight: 300;
  cursor: pointer;
  text-overflow: ellipsis;
  color: #251818;
  background-color: #ebebeb;
  border-radius: 5px;
  transition: 0.3s;
}

.artist-playlist:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.02);
}

.artist-playlist:active {
  background-color: #575757;
  transform: scale(0.98);
}

.artist:hover {
  color: rgba(57, 78, 35, 1); /* change color on hover */
  text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 1px; /* slight text shadow on hover for more remphasis */
}

@media (max-width: 768px) {
  .container {
    max-width: 85vw;
  }
  .eventsHeader h1, .artistsHeader h1 {
    font-size: 2rem; /* Adjust this value to suit your design */
  }
  .searchBar {
    font-size: 0.7rem; /* Adjust this value to suit your design */
  }
}